<template>
  <div>
    <v-card-text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla quam nec augue dictum, in egestas odio interdum. Integer ut ante et dui sodales tempus. Curabitur pulvinar id sapien quis dictum. Praesent rhoncus nulla vestibulum elit posuere, dignissim venenatis lorem semper. Pellentesque venenatis enim non leo porttitor, a euismod nibh rhoncus. Ut viverra sodales diam, ut finibus massa dignissim at. Vestibulum et nunc sed odio dapibus congue at vulputate odio. Proin mollis arcu sed aliquet maximus. Sed nisl risus, consequat a leo ut, lacinia tempus orci.</v-card-text>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>
