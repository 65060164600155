<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar density="compact" app>
      <v-app-bar-nav-icon to="/"/>
      <v-app-bar-title to="/">The Family History Scavenger Hunt</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn to="/organize">Organize A Hunt</v-btn>
      <v-btn to="/join">Join A Hunt</v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view/>
      </v-container>
    </v-main>

    <v-footer>
      <v-spacer/>
      <div>&copy; {{ new Date().getFullYear() }}&nbsp; <strong>Greg Miller</strong></div>
      <v-spacer/>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>
